<template>
    <div class="container-fluid">
        <div class="box-header">
            <div class="row">
                <div class="col-md-4">
                    <h4 class="title-in-h">{{$t('promotion.index.title')}}</h4>
                    <label v-show="info_page.status===true">{{$t('promotion.index.has')+' '+ info_page.actives+' '+$t('promotion.index.a_has_plural') }}</label>
                    <label v-show="info_page.status===false">{{$t('promotion.index.has')+' '+ info_page.inactives+' '+$t('promotion.index.i_has_plural') }}</label>
                </div>
                <div class="col-md-8 pt-2">
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='active'?'btn-success':'btn-outline'" @click="change_status('active')">{{$t('promotion.filter.vigencies')}}</button>
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='caducate'?'btn-success':'btn-outline'" @click="change_status('caducate')">{{$t('promotion.filter.caducated')}}</button>
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='future'?'btn-success':'btn-outline'" @click="change_status('future')">{{$t('promotion.filter.futures')}}</button>
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='inactive'?'btn-success':'btn-outline'" @click="change_status('inactive')">{{$t('promotion.filter.inactives')}}</button>
                </div>
            </div>
        </div>



    <div class="row">
        <div class="col-12">
            <card v-loading="screenLoading"
                  element-loading-lock="true"
                  element-loading-customClass="min-height-large"
                  :element-loading-text="screenText"
                  element-loading-spinner="el-icon-loading">
                <div>
                    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <div class="mb-3" style="width: 200px">

                            <l-button class="btn-default" @click="createItem()" v-if="writting_permission" >{{$t('promotion.index.new')}}</l-button>
                        </div>
                        <el-input type="search"
                                  class="mb-3"
                                  style="width: 200px"
                                  :placeholder="$t('promotion.index.search') "
                                  v-model="searchQuery"
                                  aria-controls="datatables"/>
                    </div>
                    <div class="col-sm-12">

                        <el-table class="table-bigboy"
                                  style="width: 100%"
                                  :data="queriedData">
                            <el-table-column min-width="150"  :label="$t('promotion.index.name') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container">
                                        <label>{{row.name}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="130"  :label="$t('promotion.index.type') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label>{{info_page.types[row.type]}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="130"  :label="$t('promotion.index.discount') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <div v-html="legendPromotion(row)" class="content-table" v-show="row.type===1"></div>
                                        <label  v-show="row.type!==1">{{legendPromotion(row)}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="90"  :label="$t('promotion.index.total') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label>${{row.reservations.length?row.reservations[0].revenue:0}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="90"  :label="$t('promotion.index.room_nigth') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label>{{row.reservations.length?row.reservations[0].rooms_night:0}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="100"  :label="$t('promotion.index.travel') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label>{{formatDate(checkDate(row.travel_dates, 'min'), 'normal')}}</label>
                                        <label>{{formatDate(checkDate(row.travel_dates, 'max'), 'normal')}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="100"  :label="$t('promotion.index.booking') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label>{{formatDate(row.booking_from, 'normal')}}</label>
                                        <label>{{formatDate(row.booking_to, 'normal')}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="100"  :label="$t('promotion.index.dias_restantes') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label>{{vigencyRestant(row.booking_to)<0?0:vigencyRestant(row.booking_to)}}</label>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column  min-width="120"  :label="$t('form.actions')" v-if="writting_permission">
                                <template slot-scope="{row}">
                                    <div class="text-center">

                                        <l-switch v-model="row.status" type="primary" on-text="Activo" off-text="Inactivo" @input="updateStatus(row._id, row.status)"></l-switch>

                                        <a v-tooltip.top-center="$t('form.edit')" class="btn-warning btn-simple btn-link ml-1"
                                           @click="handleEdit(row._id)"><i
                                                class="el-icon-edit"></i></a>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                    <div class="">
                        <p class="card-category" v-if="total>0">
                            {{$t('form.showing_a')}} {{from + 1}} {{$t('form.showing_b')}} {{to}}
                            {{$t('form.showing_c')}} {{total}} {{$t('form.showing_d')}} </p>
                    </div>
                    <l-pagination class="pagination-no-border"
                                  v-model="pagination.currentPage"
                                  :per-page="pagination.perPage"
                                  :total="pagination.total">
                    </l-pagination>
                </div>
            </card>
        </div>
    </div>
        <div class="box-footer" :class="(records.length)?'showme':''">
            <div class="box-inside text-center">
                <button class="btn btn-danger btn-wd btn-default ml-1 mr-1"  @click="cancelForm">Cancelar</button>
                <button class="btn btn-success btn-wd btn-default ml-1 mr-1"  @click="saveChanges">Guardar</button>
            </div>
        </div>
    </div>

</template>
<script>
    import {Table, TableColumn, Select, Option} from 'element-ui'
    import {Pagination as LPagination, Breadcrumb, BreadcrumbItem, Switch as LSwitch} from 'src/components/index'
    //import users from 'src/pages/Dashboard/Tables/users'
    import swal from 'sweetalert2'
    import Fuse from 'fuse.js'
    import moment from 'moment';
    import PromotionService from '../../../js/services/PromotionService';
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';
    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress'

    const promotionService = new PromotionService();
    import Settings from "src/js/helpers/Settings";
    let settings = new Settings();
    const logFunctions = new LogFunctions();
    const logService = new LogService();
    import { mapGetters } from "vuex";
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'

    export default {
        components: {
            LPagination,
            Breadcrumb,
            BreadcrumbItem,
            LSwitch,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            vueTopprogress
        },
        computed: {
            ...mapGetters(["currentUser","selected_property","writting_permission"]),
            pagedData() {
                return this.tableData.slice(this.from, this.to)
            },
            /***
             * Searches through table data and returns a paginated array.
             * Note that this should not be used for table with a lot of data as it might be slow!
             * Do the search and the pagination on the server and display the data retrieved from server instead.
             * @returns {computed.pagedData}
             */
            queriedData() {
                let result = this.tableData
                if (this.searchQuery !== '') {
                    //result = this.fuseSearch.search(this.searchQuery)
                    var fuse = new Fuse(result, this.options);
                    result = fuse.search(this.searchQuery);
                    this.pagination.total = result.length
                }
                return result.slice(this.from, this.to)
            },
            to() {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from() {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
            total() {
                this.pagination.total = this.tableData.length
                return this.tableData.length
            }
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                screenLoading: false,
                screenText: '',
                records:[],
                info_page:{
                    original_info:{},
                    section_name: 'promotion',
                    status: 'active',
                    actives: 0,
                    inactives: 0,
                    contracts: [],
                    types:[
                        this.$t('promotion.form.promotion.unique'),
                        this.$t('promotion.form.promotion.each_day'),
                        this.$t('promotion.form.promotion.always_day'),
                    ]
                },
                items: [],
                items_i: [],
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                searchQuery: '',
                propsToSearch: ['name'],
                tableData: [],
                fuseSearch: null,

                options : {
                    shouldSort: true,
                    matchAllTokens: true,
                    threshold: 0.5,
                    location: 0,
                    distance: 100,
                    maxPatternLength: 32,
                    minMatchCharLength: 1,
                    keys: [
                        "name"
                    ]
                },
                section: 'MARKETING',
            }
        },
        created(){
            this.chargerItems(this.selected_property._id);
             this.$store.dispatch('CHANGE_SECTION',this.section);
        },
        methods: {
            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='Promotions'){
                       //console.log(this.$route.name, 'Promotions')
                        this.chargerItems(property_id);
                    }
                })
            },


            async chargerItems(property_id = null){
                this.initLoading();
                let format_day = moment().format('YYYY-MM-DD');
                //console.log('contracts', contracts);
                this.info_page.property_id = property_id;
                let params = {
                    property_id: property_id,
                    status: true,
                    booking_from_fin:  moment().add(1, 'days').format('YYYY-MM-DD'),
                    booking_to_ini: format_day,
                }

                let promotions =  await promotionService.getPromotions(params);
                promotions = promotions.data.data.promotions;
               //console.log('this.items',  promotions)


                this.items = promotions;



                //this.promotions = promotions;

;



                //console.log('this.items',  rooms)
                this.info_page.actives = promotions.length;
                this.tableData = promotions;

                this.configLogs(property_id, this.info_page.section_name);
                this.closeLoading();


            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 2;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },


            handleEdit(id) {
                this.$router.push({
                    name: 'editPromotion',
                    params: {
                        id: id
                    }
                })
            },
            handleDelete(row) {
                swal({
                    title: this.$t('form.question_delete'),
                    text: this.$t('form.warning_delete'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: this.$t('form.yes_deleted'),
                    cancelButtonText: this.$t('form.cancel'),
                    buttonsStyling: false
                }).then((result)=> {
                    if (result.dismiss !== 'cancel') {
                        /*roomService.deleteRoom(row.id).then(response => {
                            swal({
                                title: this.$t('form.deleted'),
                                text: this.$t('form.been_deleted'),
                                type: 'success',
                                confirmButtonClass: 'btn btn-success btn-fill',
                                buttonsStyling: false
                            })
                            let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
                            if (indexToDelete >= 0) {
                                this.tableData.splice(indexToDelete, 1)
                            }
                        });*/
                    }
                });



            },
            createItem(){

                this.$router.push({
                    name: 'CreatePromotion'
                })
            },

            formatDate(date, typeD=null) {
                let value_r = null;
                switch (typeD) {
                    case 'short':
                        value_r = moment(date).utc().format('DD/MM');
                        break;

                    case 'normal':
                        value_r = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');

                        break;
                    default:
                        value_r = date.utc().format('YYYY-MM-DD');
                        break;

                }
                return value_r
            },

            cancelForm(){
                this.chargerItems(this.selected_property._id);
                this.info_page.status = true;
                this.records = [];

                this.$store.dispatch('CHANGES_ACTIVES', false);
                /*let tableData = this.tableData;
                this.records.forEach(item => {
                    let element = tableData.find(xquery => xquery._id === item._id)
                    element.status = !element.status;
                })
                this.records = [];*/
            },


            updateStatus(id, status){
                let records = this.records;
                status = !status;




                this.$store.dispatch('CHANGES_ACTIVES', true);

                let idx = records.findIndex(record => record._id === id);
                if(idx>=0){
                    records.splice(idx, 1);
                }else{
                    records.push({
                        _id: id,
                        status: status
                    })
                }
            },
            checkTimeZone(date){
                let gTimeZone = settings.getTimezone();

                let tzdetect =  moment(date)
                    .utc(gTimeZone)

                return tzdetect;

            },
            checkDate(datesSelected, action){

                //datesSelected = datesSelected.sort();
                let valueReturn = datesSelected[0];

                if(action==='max'){
                    valueReturn = datesSelected[datesSelected.length - 1];
                }
                return valueReturn;
            },
            vigencyRestant(endDate){
                let dateNow = moment();
                endDate = moment(endDate, 'YYYY-MM-DD');
                return (dateNow.diff(endDate, 'days'))*(-1)+1;
            },


            saveChanges(){
               //console.log('items modificados', this.records)
                let records = this.records;
                this.initLoading('Actualizando promociones');
                records.forEach(async (item, idx)=>{
                    let status =item.status;
                    let id =item._id;
                    let indexToDelete = this.tableData.findIndex((tableRow) => tableRow._id === id)
                    if (indexToDelete >= 0) {
                        this.tableData.splice(indexToDelete, 1)
                    }

                    let params = {
                        status: !status,
                        _id: id
                    }


                    let promotion = await promotionService.updatePromotion(params)
                    promotion = promotion.data.data.promotion
                    if(promotion.status===false){
                        await this.createLog(promotion._id, 2, []);
                    }

                    this.closeLoading();


                });
                this.records = [];

               /* setTimeout(() => {
                    let indexToDelete = this.tableData.findIndex((tableRow) => tableRow._id === id)
                    if (indexToDelete >= 0) {
                        this.tableData.splice(indexToDelete, 1)
                    }

                    if(status===true){
                        indexToDelete = this.items_i.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items_i.splice(indexToDelete, 1)
                        }
                        status = 'true';

                    }else{
                        indexToDelete = this.items.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items.splice(indexToDelete, 1)
                        }
                        status = 'false';
                    }

                    roomService.updateStatus(id, status).then(response => {

                        let room = response.data.data.room;
                        if(room.status===true){
                            this.items.push(room)
                        }else{
                            this.items_i.push(room)
                        }

                        this.info_page.actives =  this.items.length;
                        this.info_page.inactives =  this.items_i.length;
                    });
                }, 800);*/
            },
            descuentoTranslate(){

            },

            async change_status(type){
                this.initLoading();
                //console.log(this.info_page.actives, this.info_page.inactives)
                let items = [];
                this.info_page.status = type;

                let property_id = this.info_page.property_id;
                let format_day = moment().format('YYYY-MM-DD');
                //console.log('contracts', contracts);
                let params = {};


                if(type === 'active'){
                    params = {
                        property_id: property_id,
                        status: true,
                        booking_from_fin:  moment().add(1, 'days').format('YYYY-MM-DD'),
                        booking_to_ini: format_day,
                    }
                }else if(type === 'caducate'){
                    params = {
                    property_id: property_id,
                    booking_to_fin: format_day,
                    //status: 'all',
                    status: true,

                }
                }else if(type === 'future'){
                    params = {
                        property_id: property_id,
                        status: true,
                        booking_from_ini: moment().add(1, 'days').format('YYYY-MM-DD'),
                        //booking_from_ini: format_day,
                    }
                }else if(type === 'inactive'){
                    params = {
                        property_id: property_id,
                        /*booking_from_fin:  moment().add(1, 'days').format('YYYY-MM-DD'),
                        booking_to_ini: format_day,*/
                        status: false,
                    }
                }

                let promotions =  await promotionService.getPromotions(params);
                promotions = promotions.data.data.promotions;
               //console.log('this.items',  promotions)
                this.info_page.actives = promotions.length;
                this.tableData = promotions;

                this.closeLoading();
            },
            trans_status(status){
                let result = 'Inactivo';
                if(status){
                    result = 'Activo';
                }
                return result
            },


            nightDiscount(dayIs){
                let days = ['','1ra', '2da', '3ra', '4ta', '5ta', '6ta', '7ma'];
                return days[dayIs];
            },
            dayShort(dayIs){
                let days = ['Dom','Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
                return days[dayIs];
            },
            legendPromotion(promotion){
                let promotionType = promotion.type;
                let dataReturn = '';
                let dataAux = '';


                if(promotionType===0){
                    if(promotion.discount_type==='%')
                        dataReturn = promotion.value+' '+promotion.discount_type+' de descuento';
                    if(promotion.discount_type==='$')
                        dataReturn = promotion.discount_type+' '+promotion.value+' MXN de descuento';
                }if(promotionType===1){

                   //console.log('promotions', promotion.value_days);
                    if(promotion.value_days){
                        promotion.value_days.forEach((itemDay, idxK)=>{
                            if(idxK!==0){
                                dataReturn+='<label> <span>'+this.dayShort(idxK)+'</span><br>'+itemDay+'%'+'</label>'
                            }else{
                                dataAux='<label> <span>'+this.dayShort(idxK)+'</span><br>'+itemDay+'%'+'</label>';
                            }
                        })
                    }



                    dataReturn +=dataAux;

                    /*primerDia = null;
                    $nombreDia = null;
                    $tzo =  config('app.timezoneMex');
                    foreach ($dataRates as $rateDay){
                        //dd($rateDay);
                        if(is_null($primerDia) && isset($rateDay->no_promo_value)){
                            $primerDia = Carbon::parse($rateDay->date)->dayOfWeek;
                            $nombreDia = Date::parse($rateDay->date)->format('l');
                        }
                    }
                    dataReturn = trans('general.promotion.night_day', ['day'=> ucfirst($nombreDia), 'value'=>promotion->value_days[$primerDia]]);*/

                }if(promotionType===2){
                    if(promotion.discount_type==='1'){
                        dataReturn = this.nightDiscount(promotion.value)+ ' Noche Gratis';
                    }
                    if(promotion.discount_type==='2'){
                        dataReturn = promotion.discount_night+'% Noche Gratis '+promotion.value;
                        dataReturn = promotion.discount_night+'% de Descuento en la '+promotion.value +' Noche';
                    };
                }

                return dataReturn;
            },
            initLoading(text = 'Cargando...') {
                if(text==='Cargando...'){
                    this.$store.dispatch('CHANGES_ACTIVES', false);
                    this.records = [];
                }

                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }


        },
        mounted() {
            this.changeProperty()
        }
    }
</script>
<style lang="scss">
    .el-table{
        table{
            .cell{
                word-break: break-word;
                .lbl-container{
                    label{
                        word-break: break-word;
                    }
                }
            }
        }

    }
    .custom-lbl{
        label{
            color: #23CCEF;
            font-weight: 600;
        }
        span{
            width: 100%;
            display: inline-block;

        }
    }
    .content-table label{
        display: inline-block;
        width: 25%;
        font-size: 11px;
        text-transform: unset;
        order: 2;
        &:first-child{
            order: 3;
        }
    }
    .content-table label span{
        font-weight: 600;
    }
</style>
